import {useExp3} from '@eda-restapp/configs'
import UnreadCounter from '@yandex-int/messenger.unread-counter'
import type {FC} from 'react'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {selectIsLoggedIn, selectOauthToken} from '@restapp/core-auth'

import {chatsSlice} from '../../../slice/chatsSlice'
import {getCounterUrl} from '../../getCounterUrl'
import {messengerEventLogger, messengerErrorLogger} from '../../logger'

const errorLogger = messengerErrorLogger.namespace('unread_counter_watcher')
const eventLogger = messengerEventLogger.namespace('unread_counter_watcher')

export const UnreadCounterWatcher: FC = () => {
  const dispatch = useDispatch()
  const oauthToken = useSelector(selectOauthToken)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const singleChatOpen = useSelector(chatsSlice.selectors.selectSingleChatOpen)
  const multiChatOpen = useSelector(chatsSlice.selectors.selectMultiChatDrawerState)

  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')
  const supportSingleChatConfig = useExp3('restapp_support_single_chat')

  useEffect(() => {
    try {
      const isDisabledByConfig =
        !supportMultiChatConfig.backgroundUnreadCounter && !supportSingleChatConfig.backgroundUnreadCounter

      if (!isLoggedIn || isDisabledByConfig || singleChatOpen || multiChatOpen) {
        return
      }

      const unreadCounter = new UnreadCounter({
        url: getCounterUrl(supportMultiChatConfig.backend),
        authToken: oauthToken ? `OAuth ${oauthToken}` : undefined,
        serviceId: supportMultiChatConfig.serviceId,
        workspaceId: supportMultiChatConfig.workspace,
        withCountChats: true,
        callback: (data) => {
          if (data.value !== undefined) {
            dispatch(chatsSlice.actions.setUnreadMessagesCount(data.value))
          }

          if (data.chatCount !== undefined) {
            dispatch(chatsSlice.actions.setUnreadChatsCount(data.chatCount))
          }

          eventLogger({
            name: 'callback',
            value: data.value,
            additional: {data}
          })
        }
      })

      unreadCounter.start()

      return () => {
        unreadCounter.stop()
      }
    } catch (error) {
      errorLogger({
        error: error instanceof Error ? error : String(error),
        additional: {
          isLoggedIn,
          oauthTokenLength: oauthToken?.length
        }
      })
    }
  }, [
    dispatch,
    isLoggedIn,
    multiChatOpen,
    oauthToken,
    singleChatOpen,
    supportMultiChatConfig.backend,
    supportMultiChatConfig.backgroundUnreadCounter,
    supportMultiChatConfig.serviceId,
    supportMultiChatConfig.workspace,
    supportSingleChatConfig.backgroundUnreadCounter
  ])

  return null
}
