import React from 'react'
import {useSelector} from 'react-redux'

import {useApiQuery} from '@restapp/core-api'
import {chatsSlice} from '@restapp/main-chats/slice/chatsSlice'

import SidebarBadge, {BadgesType} from '../SidebarBadge'

const UnreadChatsBadge: React.FC = () => {
  const messengerUnreadChatsCount = useSelector(chatsSlice.selectors.selectUnreadChatsCount)

  const {data} = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v1/partner/notifications/count',
    method: 'GET',
    staleTime: 180_000,
    refetchOnSocketEvent: ['new_message', 'no_unread_messages']
  })

  const unreadCount = (data?.count || 0) + messengerUnreadChatsCount

  if (unreadCount < 1) {
    return null
  }

  return <SidebarBadge type={BadgesType.NOTIFY_COUNT}>{unreadCount >= 1000 ? '999+' : unreadCount}</SidebarBadge>
}

export default UnreadChatsBadge
