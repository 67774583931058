import {useExp3} from '@eda-restapp/configs'
import {errorLogger} from '@eda-restapp/logger'
import {useEffect, useRef, useState, useCallback, useMemo} from 'react'

import {useYandexLogin, usePromocodeType} from '../../hooks'
import {usePromocode} from '../../hooks/usePromocode'
import {createRequisitesPaymentUrl} from '../../utils'

import type {PaymentJson} from './types'
import {parseDirectData} from './utils'

type UseDirectMessageProps = {close: (success: boolean) => void; directUsername: string}

export const useDirectMessage = ({close, directUsername}: UseDirectMessageProps) => {
  const frameRef = useRef<HTMLIFrameElement>(null)

  const config = useExp3('restapp_promotion')
  const {login} = useYandexLogin()

  const promocodeType = usePromocodeType()
  const promocode = usePromocode(promocodeType.value)
  const refetchPromocode = promocode.refetch

  const [isSelfContainedFrame, setIsSelfContainedFrame] = useState(true)

  const urlOptions = useMemo(() => {
    return {
      login: directUsername,
      origin: config.direct.origin,
      paymentUrl: config.direct.paymentUrl,
      cookieResolverUrl: config.direct.cookieResolverUrl,
      isUseCookieResolver: config.direct.enableCookieResolver || !!document.hasStorageAccess,
      promocode: promocode.promocode,
      ...config.direct.paymentLink
    }
  }, [
    directUsername,
    config.direct.origin,
    config.direct.paymentUrl,
    config.direct.cookieResolverUrl,
    config.direct.enableCookieResolver,
    promocode.promocode,
    config.direct.paymentLink
  ])

  const [[frameUrl, ...nextUrls], setUrlQueue] = useState<string[]>([])
  const [[frameWidth, frameHeight], setFrameSize] = useState<[number?, number?]>([undefined, undefined])

  const logDirectPaymentError = useCallback((json: PaymentJson) => {
    if (!('source' in json) || json.source !== 'direct-payment') {
      return
    }

    void errorLogger({
      message: 'direct:payment-popup-error',
      level: 'error',
      error: new Error('direct:payment-popup-error'),
      additional: {
        eventData: json
      }
    })
  }, [])

  const messageHandler = useCallback(
    (event: MessageEvent<string>) => {
      if (frameRef?.current?.contentWindow !== event.source || !directUsername) {
        return
      }

      const json = parseDirectData(event.data)

      if (json instanceof Error) {
        errorLogger({
          level: 'error',
          error: json,
          message: json.message,
          additional: {eventData: event.data, eventSlug: 'direct-payment-popup-message-parse-failed'}
        })
        return
      }

      if ('source' in json && json.source === 'direct-payment') {
        switch (json.type) {
          case 'resize':
            setFrameSize([json.data.width, json.data.height])
            return
          case 'open-payment': {
            const steps = [json.data.firstStepUrl, json.data.secondStepUrl].filter((url) => url)

            if (steps.length > 0) {
              setUrlQueue([...nextUrls, ...steps])
              setIsSelfContainedFrame(false)
            } else {
              void refetchPromocode()
              close(true)
            }
            return
          }
          case 'incorrect-auth':
          case 'user-unauthorized':
          case 'direct-client-missing':
            logDirectPaymentError(json)
            login()
            return
          case 'direct-reject-cookie':
            logDirectPaymentError(json)
            close(false)
            return
          case 'close':
            close(false)
            return
          case 'direct-cookie-access-allowed':
            setUrlQueue([
              createRequisitesPaymentUrl({
                ...urlOptions,
                isUseCookieResolver: false
              })
            ])
            return
          case 'direct-open-policy-link':
            window.open(json.uri, '_blank')
            return
          default:
            return
        }
      }

      if ('source' in json && json.source === 'YandexTrustPaymentForm' && json.type === 'payment-complete') {
        close(true)
        return
      }

      // Необрабатываемые сообщения
      if (
        ('type' in json && json.type === 'sr') ||
        ('__yminfo' in json && json.data?.type === 'initToParent') ||
        (event.isTrusted && !event.data)
      ) {
        return
      }

      errorLogger({
        level: 'error',
        error: new Error('New message from direct'),
        message: 'New message from direct',
        additional: {event, eventSlug: 'direct-payment-popup-new-message-type'}
      })
    },
    [directUsername, login, close, urlOptions, nextUrls, refetchPromocode, logDirectPaymentError]
  )

  useEffect(() => {
    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [messageHandler])

  const frameUrlUnlessWaitForPromocode =
    promocode.isEnabled && promocode.isFetching && !(frameWidth || frameHeight)
      ? undefined
      : createRequisitesPaymentUrl(urlOptions)

  return {isSelfContainedFrame, frameUrl: frameUrl ?? frameUrlUnlessWaitForPromocode, frameWidth, frameHeight, frameRef}
}
