import {useI18n} from '@eda-restapp/i18n'
import {Box, Drawer, useIsMobileStrict} from '@eda-restapp/ui'
import {type FC, lazy, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useApiMutation} from '@restapp/core-api'
import {ErrorBoundary, Suspense} from '@restapp/shared-boundary'
import {useAppStore} from '@restapp/store'

import {SupportTreeContainer} from '../../../components/SupportTree'
import {chatsSlice} from '../../../slice/chatsSlice'
import {messengerEventLogger} from '../../logger'
import {SupportWidget} from '../SupportWidget'

const eventLogger = messengerEventLogger.namespace('multi_chat_drawer')

const Widget = lazy(() =>
  import(/* webpackChunkName: "@restapp/main-chats" */ '../MultiChatWidget').then((module) => {
    return {default: module.MultiChatWidget}
  })
)
export const MultiChatDrawer: FC = () => {
  const errorHandler = useErrorBoundaryHandler()
  const isMobile = useIsMobileStrict()
  const {t} = useI18n()
  const dispatch = useDispatch()
  const opened = useSelector(chatsSlice.selectors.selectMultiChatDrawerState)
  const treePath = useSelector(chatsSlice.selectors.selectSupportTreePath)
  const canTravelBack = useSelector(chatsSlice.selectors.selectSupportTreeCanTravelBack)
  const chatId = useSelector(chatsSlice.selectors.selectMultiChatId)
  const ticketId = useSelector(chatsSlice.selectors.selectMultiChatTicketId)
  const createParams = useSelector(chatsSlice.selectors.selectMultiChatCreateParams)

  const leafId = treePath[0] // TODO: нужен фоллбек на тему "Другой вопрос" - сделать селектор в redux
  const chatWidgetView = !!chatId || !!ticketId

  const createChatMutation = useApiMutation(
    {
      url: '/4.0/restapp-front/support_chat/v2/create_chat',
      method: 'POST'
    },
    {
      onSuccess: ({chat_id, ticket_id}) => {
        dispatch(chatsSlice.actions.openMultiChatDrawer({chatId: chat_id, ticketId: ticket_id}))
      }
    }
  )

  const createChatWithFormsMutation = useApiMutation(
    {
      url: '/4.0/restapp-front/support_chat/v2/create_chat_with_forms',
      method: 'POST'
    },
    {
      onSuccess: ({chat_id, ticket_id}) => {
        dispatch(chatsSlice.actions.openMultiChatDrawer({chatId: chat_id, ticketId: ticket_id}))
      }
    }
  )

  const createChatHandler = () => {
    if (leafId) {
      createChatMutation.mutate({
        body: {
          leaf_slug_id: leafId,
          order_nrs: createParams.orderId ? [createParams.orderId] : undefined,
          place_ids: createParams.placeId ? [createParams.placeId].map(String) : undefined
        }
      })
    }
  }

  const createChatWithFormsHandler = (answerKey: string) => {
    if (leafId) {
      createChatWithFormsMutation.mutate({
        body: {
          leaf_id: leafId,
          place_ids: createParams.placeId ? [createParams.placeId].map(String) : undefined,
          forms_answer_key: answerKey
        }
      })
    }
  }

  return (
    <Drawer.Panel opened={opened} onClose={() => dispatch(chatsSlice.actions.closeMultiChatDrawer())}>
      <Drawer.Container>
        <Drawer.Header
          border
          crossBtn
          onClose={() => dispatch(chatsSlice.actions.closeMultiChatDrawer())}
          backBtn={canTravelBack}
          onBack={() => dispatch(chatsSlice.actions.supportTreeBack())}
        >
          {t('main-chats.messenger.multi-chat-header', 'Новое обращение')}
        </Drawer.Header>

        <Drawer.Content>
          <Box flexGrow={1} style={{position: 'relative'}}>
            <ErrorBoundary slug='messenger_multi_chat_error_boundary' onError={errorHandler}>
              <Suspense slug='messenger_multi_chat_error_suspense' onError={errorHandler}>
                {chatWidgetView && <Widget chatId={chatId} ticketId={ticketId} />}

                {!chatWidgetView && (
                  <Box px='s' pt='m' flexGrow={1}>
                    <SupportTreeContainer
                      style={{width: '100%'}}
                      path={treePath}
                      onChange={(id) => dispatch(chatsSlice.actions.supportTreeForward(id))}
                    >
                      {(widgets) => {
                        return (
                          <Box flexDirection='column' flexGrow={1} pb='m' px={isMobile ? 's' : 'm'}>
                            {widgets?.map((widget) => (
                              <SupportWidget
                                key={widget.id}
                                widget={widget}
                                isNewChatCreating={createChatMutation.isLoading}
                                isNewChatWithFormsCreating={createChatWithFormsMutation.isLoading}
                                onNewChat={createChatHandler}
                                onFormSent={createChatWithFormsHandler}
                              />
                            ))}
                          </Box>
                        )
                      }}
                    </SupportTreeContainer>
                  </Box>
                )}
              </Suspense>
            </ErrorBoundary>
          </Box>
        </Drawer.Content>
      </Drawer.Container>
    </Drawer.Panel>
  )
}

function useErrorBoundaryHandler() {
  const store = useAppStore()
  return useCallback(
    (error: unknown) => {
      eventLogger({
        name: 'redux_snapshot',
        value: String(error),
        additional: {
          chatsSliceSnapshot: chatsSlice.selectSlice(store.getState())
        }
      })
    },
    [store]
  )
}
