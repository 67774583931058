import type {ComponentClass, ComponentType} from 'react'
import React, {useMemo} from 'react'
import {SectionSlug} from '@restapp/shared-api'
import {Suspense, ErrorBoundary} from '@restapp/shared-boundary'
import {Routes, Route, Navigate, useLocation} from 'react-router-dom'
import {observer} from 'mobx-react'
import {RouteContext} from '../../../routes/utils'
import {usePassportEnabled} from '@restapp/core-auth/passport/hooks'

import {PassportRestrictionPageContainer} from '@restapp/core-auth/passport/components/PassportMigrationContainer'

const AccountRoot = React.lazy(() =>
  import(/* webpackChunkName: "@restapp/account" */ '@restapp/account').then((module) => {
    return {default: module.AccountRoot}
  })
)

const MicrofrontendLazy = React.lazy(() =>
  import(/* webpackChunkName: "@restapp/shared/microfrontend" */ '@restapp/shared/microfrontend').then((module) => {
    return {default: module.Microfrontend}
  })
)

interface ComponentRoute {
  path: string
  component:
    | React.LazyExoticComponent<ComponentType>
    | React.LazyExoticComponent<React.FC>
    | React.LazyExoticComponent<ComponentClass>
}

export interface IFrameRoute {
  id: string
  path: string
}

interface GlobalRoutingProps {
  routesMap: Partial<Record<SectionSlug, ComponentRoute>>
  routesIds: SectionSlug[]
  labelsMap: Record<string, string>
  routesIFrame: IFrameRoute[]
  mainRouteId: SectionSlug
}

const DEFAULT_MAIN_ROUTE = '/'

// TODO: https://st.yandex-team.ru/EDARESTAPP-9218
const GlobalRouting: React.FC<GlobalRoutingProps> = ({routesMap, routesIds, mainRouteId, routesIFrame, labelsMap}) => {
  const passportEnabled = usePassportEnabled()
  const location = useLocation()

  const mainPagePath = useMemo(() => {
    const pathFromMap = routesIds.includes(mainRouteId) ? routesMap[mainRouteId]?.path : null
    const pathFromIFrame = routesIFrame.find((route) => route.id === mainRouteId)?.id

    if (pathFromIFrame) {
      return `/${pathFromIFrame}`
    }

    return `${pathFromMap || DEFAULT_MAIN_ROUTE}`
  }, [mainRouteId, routesIds, routesIFrame, routesMap])

  return (
    <Routes>
      {routesIds.map((routeId) => {
        const route = routesMap[routeId]

        if (!route) {
          // eslint-disable-next-line no-console
          console.warn(`Route object not found for id ${routeId}`)
          return null
        }

        const RouteComponent = route.component

        return (
          <Route
            key={route.path}
            path={route.path + '/*'}
            element={
              <RouteContext.Provider value={{sectionSlug: routeId}}>
                <ErrorBoundary withMenuButton slug='route-error-boundary'>
                  <Suspense slug='route-suspense'>
                    <PassportRestrictionPageContainer headerTitle={labelsMap[routeId]}>
                      <RouteComponent />
                    </PassportRestrictionPageContainer>
                  </Suspense>
                </ErrorBoundary>
              </RouteContext.Provider>
            }
          />
        )
      })}

      {routesIFrame.map((route) => (
        <Route
          key={`${route.id}/${route.path}`}
          path={route.id + '/*'}
          element={
            <ErrorBoundary withMenuButton slug='microfrontend-route-error-boundary'>
              <Suspense slug='microfrontend-route-suspense'>
                <PassportRestrictionPageContainer headerTitle={labelsMap[route.id]}>
                  <MicrofrontendLazy key={route.id} id={route.id} path={route.path} />
                </PassportRestrictionPageContainer>
              </Suspense>
            </ErrorBoundary>
          }
        />
      ))}

      {passportEnabled.enabled && (
        <Route
          key='account'
          path='/account/*'
          element={
            <RouteContext.Provider value={{sectionSlug: SectionSlug.account}}>
              <Suspense slug='route-account'>
                <AccountRoot />
              </Suspense>
            </RouteContext.Provider>
          }
        />
      )}

      {location.pathname !== mainPagePath && (
        // @TODO Возможно здесь нужна реальная 404 страница, в случае если нет ни одного доступного раздела,
        // кроме сайдбара будет отображаться только пустой экран
        <Route path='*' element={<Navigate to={mainPagePath + location.search} replace />} />
      )}
    </Routes>
  )
}

export default observer(GlobalRouting)
