import {useExp3} from '@eda-restapp/configs'
import {errorLogger} from '@eda-restapp/logger'
import {useCallback} from 'react'

import type {ButtonProps} from '@restapp/shared-ui'

import {createOauthUrl} from '../helpers'
import {getBasename} from '../utils'

/**
 * Current auth flow
 *
 * - Create OAuth link
 * - Append OAuth link to query string param "retpath" for complete registration URL
 * - Check user's Direct profile in complete registration URL:
 * -- if more info is needed, show form, after filling, redirect to auth link
 * -- if info is complete, instantly redirect to OAuth link
 */

type UseYandexLogin =
  | {
      onClick?: ButtonProps['onClick']
      redirectUrl?: string
    }
  | undefined

const useYandexLogin = ({onClick, redirectUrl = `${location.pathname}${location.search}`}: UseYandexLogin = {}) => {
  const basename = getBasename()
  const isBasenameInRedirectUrl = redirectUrl.startsWith(basename)
  const promotionConfig = useExp3('restapp_promotion')

  let completeRegistrationUrl: URL | undefined
  let oauthUrl: string | undefined
  let error: Error | undefined

  try {
    oauthUrl = createOauthUrl(promotionConfig.direct.oauthLink, {
      inAppRedirect: redirectUrl,
      basename: isBasenameInRedirectUrl ? '' : basename
    })
  } catch (e) {
    error = e instanceof Error ? e : new Error('Error creating oauth url', {cause: e})
  }

  try {
    if (oauthUrl) {
      completeRegistrationUrl = new URL(promotionConfig.direct.completeDirectAccountLink)
      completeRegistrationUrl.searchParams.append('retpath', oauthUrl)
    }
  } catch (e) {
    error = e instanceof Error ? e : new Error('Error creating complete registration url', {cause: e})
  }

  const onClickHandler: ButtonProps['onClick'] = (e) => {
    onClick?.(e)
  }

  const login = useCallback(
    <T>(e?: React.MouseEvent<T, MouseEvent>) => {
      e?.preventDefault()

      if (error) {
        errorLogger({
          level: 'error',
          error: error,
          message: error.message,
          additional: {eventSlug: 'promotion:login-to-direct-failed'}
        })
        return
      }

      if (completeRegistrationUrl) {
        location.href = completeRegistrationUrl.toString()
      }
    },
    [completeRegistrationUrl, error]
  )

  return {onClick: onClickHandler, login, href: completeRegistrationUrl?.toString()}
}

export default useYandexLogin
