import type {PaymentJson} from './types'

export const parseDirectData = (data: unknown): PaymentJson | Error => {
  try {
    if (typeof data === 'string') {
      return JSON.parse(data) as PaymentJson
    }

    return data as PaymentJson
  } catch (error) {
    return new Error('Error parsing direct message', {cause: error})
  }
}
