import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Snackbar} from '@eda-restapp/snackbars'
import {useDispatch} from 'react-redux'

import type {LegacyNewChatParams} from '../slice/chatsSlice'
import {chatsSlice} from '../slice/chatsSlice'

interface UseSupportChat {
  openNewSupportChat: (
    metadata?: LegacyNewChatParams & {
      /**
       * Путь для открытия конкретной ветки дерева тематик в новых чатах.
       * Если вы хотите хранить это значение в конфиге - пожалуйста согласуйте конфиг с командой мейнтенеров.
       * @todo завести единый конфиг для маппинга путей
       */
      supportTreePath?: string[]
    }
  ) => void
  openChat: (chatId: string) => void
}

export function useSupportChat(): UseSupportChat {
  const {t} = useI18n()
  const supportSingleChatConfig = useExp3('restapp_support_single_chat')
  const supportMultiChatConfig = useExp3('restapp_support_multi_chat')
  const dispatch = useDispatch()

  return {
    openNewSupportChat: (metadata = {}) => {
      if (supportMultiChatConfig.overrideChatCreation) {
        const legacyPathFallback = {
          // TODO: предусмотреть фоллбеки на metadata.path когда станет понятно какие будут id
          // '/eda-restaurants/partnership.html': ['orders', 'cancel_order']
        } as Record<string, string[]>

        dispatch(
          chatsSlice.actions.openMultiChatDrawerWithSupportTree({
            path: metadata.supportTreePath ?? legacyPathFallback[metadata.path || ''],
            orderId: metadata.order_id,
            placeId: metadata.place_id
          })
        )
        return
      }

      if (supportSingleChatConfig.overrideChatCreation) {
        dispatch(
          chatsSlice.actions.openSingleChat({
            orderId: metadata.order_id,
            placeId: metadata.place_id
          })
        )
        return
      }

      dispatch(chatsSlice.actions.setLegacyNewChatParams(metadata))
    },
    openChat: (chatId: string) => {
      if (supportSingleChatConfig.blockChatOpening) {
        Snackbar.error({
          text: t('main-chats.support-chats.chat-open-blocked', 'Извините, сейчас не можем открыть обращение')
        })
        return
      }

      dispatch(chatsSlice.actions.openLegacyChat(chatId))
    }
  }
}
