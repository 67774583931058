import {combineSlices} from '@reduxjs/toolkit'

import {nativeSlice} from '@restapp/bootstrap/native/slice/native.slice'
import {serviceSlice} from '@restapp/bootstrap/serviceSlice/service.slice'
import {snackbarsSlice} from '@restapp/bootstrap/snackbars/slice/snackbars.slice'
import {soundsSlice} from '@restapp/bootstrap/sounds/slice/sounds.slice'
import {authSlice} from '@restapp/core-auth/slice'
import networkSlice from '@restapp/core-legacy/common/components/App/Network/networkSlice'
import {PLACES_DROPDOWN_REDUCER_KEY} from '@restapp/core-places'
import {placesDropdownReducer} from '@restapp/core-places/slice'
import {chatsSlice} from '@restapp/main-chats/slice/chatsSlice'
import {COMMUNICATIONS_REDUCER_KEY, communicationsReducer} from '@restapp/main-communications/slice'
import {LAYOUT_REDUCER_KEY, layoutReducer} from '@restapp/main-layout'
import {PLACES_SCHEDULE_SLICE_KEY, placesScheduleReducer} from '@restapp/main-places'
import {internationalSlice} from '@restapp/shared/slice'
import {MenuStore} from '@restapp/shared-menu'

import {makeSlicePersistent, makeReducerPersistent} from './persist'

/**
 * @deprecated
 * Не разбивайте слайсы на составляющие без необходимости. Держите все экшены и селекторы прямо в слайсе до тех пор пока не понадобится обратное. Не экспортируйте ничего кроме слайса, если в этом нет надобности.
 * https://redux.js.org/style-guide/#structure-files-as-feature-folders-with-single-file-logic
 *
 * Пример хорошего слайса:
 * https://a.yandex-team.ru/arcadia/taxi/frontend/services/eda-restapp-core/packages/store/slices/sounds.slice.ts?rev=r14803019#L13
 *
 * Пример плохого слайса:
 * https://a.yandex-team.ru/arcadia/taxi/frontend/services/eda-restapp-core/packages/shared-menu/store/menu/index.ts?rev=r14803019#L42
 *
 */
const legacySlices = [
  {
    reducerPath: PLACES_DROPDOWN_REDUCER_KEY,
    reducer: placesDropdownReducer
  },
  {
    reducerPath: PLACES_SCHEDULE_SLICE_KEY,
    reducer: placesScheduleReducer
  },
  {
    reducerPath: LAYOUT_REDUCER_KEY,
    reducer: layoutReducer
  },
  {
    reducerPath: MenuStore.slice.name,
    reducer: MenuStore.slice.reducer
  },
  {
    reducerPath: internationalSlice.name,
    reducer: makeReducerPersistent(internationalSlice.reducer, internationalSlice.name)
  },
  {
    reducerPath: authSlice.name,
    reducer: makeReducerPersistent(authSlice.reducer, authSlice.name)
  },
  {
    reducerPath: networkSlice.name,
    reducer: networkSlice.reducer
  },
  {
    reducerPath: COMMUNICATIONS_REDUCER_KEY,
    reducer: communicationsReducer
  }
] as const

export const rootReducer = combineSlices(
  makeSlicePersistent(soundsSlice),
  chatsSlice,
  snackbarsSlice,
  nativeSlice,
  serviceSlice,
  ...legacySlices
)
