import {ChevronRightMIcon, Clamp, Typography} from '@eda-restapp/ui'
import cn from 'classnames'
import React from 'react'
import type {CSSProperties, FC} from 'react'

import styles from './SupportTreeListItem.module.css'

export type SupportTreeListItemProps = {
  title: string
  onClick: () => void

  className?: string
  style?: CSSProperties
}

export const SupportTreeListItem: FC<SupportTreeListItemProps> = ({title, onClick, className, style}) => {
  return (
    <div onClick={onClick} className={cn(styles.container, className)} style={style}>
      <Typography variant='body2' group='tight' color='main'>
        <Clamp>{title}</Clamp>
      </Typography>

      <ChevronRightMIcon />
    </div>
  )
}
