import {MarkdownContainer, Button} from '@eda-restapp/ui'
import React from 'react'
import type {FC} from 'react'

import {messengerErrorLogger} from '../../logger'
import {YaFormsWidget} from '../YaFormsWidget/YaFormsWidget'

import styles from './SupportWidget.module.css'

const errorLogger = messengerErrorLogger.namespace('support_widget')

export type SupportWidgetProps = {
  widget: {
    id: string
    type: string
    content?: string
    href?: string
  }
  isNewChatCreating: boolean
  isNewChatWithFormsCreating: boolean
  onNewChat: () => void
  onFormSent: (answerKey: string) => void
}

export const SupportWidget: FC<SupportWidgetProps> = ({
  widget,
  onNewChat,
  onFormSent,
  isNewChatCreating,
  isNewChatWithFormsCreating
}) => {
  switch (widget.type) {
    case 'new_chat':
      if (widget.content) {
        return (
          <Button.Text loading={isNewChatCreating} onClick={onNewChat} fullWidth size='l'>
            {widget.content}
          </Button.Text>
        )
      }

      errorLogger({
        error: 'received_new_chat_widget_without_content',
        additional: {widget_id: widget.id}
      })

      return null
    case 'text_markdown':
      if (widget.content) {
        return <MarkdownContainer markdown={widget.content} />
      }

      errorLogger({
        error: 'recieved_text_markdown_widget_without_content',
        additional: {widget_id: widget.id}
      })

      return null
    case 'form':
      if (widget.href) {
        return (
          <YaFormsWidget
            isNewChatWithFormsCreating={isNewChatWithFormsCreating}
            onFormSent={(answerKey) => onFormSent(answerKey)}
            href={widget.href}
          />
        )
      }

      throw new Error('recieved_form_widget_without_href')
    case 'spacer':
      return <div className={styles.spacer} />
    default:
      errorLogger({
        error: 'recieved_unknown_widget_type',
        additional: {widget_id: widget.id, widget_type: widget.type}
      })

      return null
  }
}
