import {useI18n} from '@eda-restapp/i18n'
import {Spinner} from '@eda-restapp/ui'
import React, {useEffect, useState} from 'react'
import type {FC} from 'react'

import {messengerErrorLogger, messengerEventLogger} from '../../logger'

import {parseYaFormWidgetEvent} from './parseYaFormWidgetEvent'
import styles from './YaFormsWidget.module.css'

export type YaFormsWidgetProps = {
  href: string
  isNewChatWithFormsCreating?: boolean

  onFormSent: (answer_key: string) => void
}

const YA_FORMS_WIDGET_IFRAME_ID = 'ya-forms-widget-iframe'

const errorLogger = messengerErrorLogger.namespace('ya-forms')
const eventLogger = messengerEventLogger.namespace('ya-forms')

export const YaFormsWidget: FC<YaFormsWidgetProps> = ({href, isNewChatWithFormsCreating, onFormSent}) => {
  const {lang} = useI18n()

  const {onLoadComplete, isFormLoaded} = useYaFormWidgetTimeout(href)

  useEffect(() => {
    const cb = (ev: MessageEvent) => {
      if (!isYaFormWidgetMessage(ev)) {
        return
      }

      const parsedEvent = parseYaFormWidgetEvent(ev)

      if (parsedEvent === null) {
        errorLogger({
          error: 'failed_to_parse_ya_forms_widget_message',
          additional: {href}
        })

        return
      }

      switch (parsedEvent.type) {
        case 'ping':
          return onLoadComplete()
        case 'sent':
          return onFormSent(parsedEvent.answer_key)
      }
    }

    window.addEventListener('message', cb)

    return () => window.removeEventListener('message', cb)
  }, [href, isFormLoaded, onFormSent, onLoadComplete])

  const yaFormsWidgetHref = `${href}?iframe=1&lang=${lang}`

  const isLoading = !isFormLoaded || isNewChatWithFormsCreating

  return (
    <>
      {isLoading && (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}

      <iframe
        id={YA_FORMS_WIDGET_IFRAME_ID}
        name={YA_FORMS_WIDGET_IFRAME_ID}
        src={yaFormsWidgetHref}
        frameBorder={0}
        width='100%'
        height='100%'
      />
    </>
  )
}

const isYaFormWidgetMessage = (ev: MessageEvent) => {
  const widget = document.getElementById(YA_FORMS_WIDGET_IFRAME_ID)

  return Boolean(widget && 'contentWindow' in widget && widget.contentWindow === ev.source)
}

const YA_FORMS_WIDGET_LOADING_TIMEOUT_MS = 5000

const useYaFormWidgetTimeout = (href: string) => {
  const [isFormLoaded, setFormLoaded] = useState(false)
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const timerId = setTimeout(() => {
      errorLogger({
        error: 'failed_to_load_ya_forms_widget',
        additional: {
          href
        }
      })
    }, YA_FORMS_WIDGET_LOADING_TIMEOUT_MS)

    setTimerId(timerId)

    return () => {
      clearInterval(timerId)
      setTimerId(null)
    }
  }, [href])

  const onLoadComplete = () => {
    if (timerId) {
      eventLogger({
        name: 'ya_forms_widget_loaded',
        value: href,
        additional: {}
      })

      clearTimeout(timerId)
    }

    setFormLoaded(true)
    setTimerId(null)
  }

  return {
    onLoadComplete,
    isFormLoaded
  }
}
