import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {Moment} from 'moment'
import moment from 'moment'

import {startAppListening} from '@restapp/store/listenerMiddleware'

const initialState: ChatsSliceState = {
  singleChat: {
    open: false,
    openParams: {}
  },
  multiChat: {
    open: false,
    createParams: {},
    openParams: {},
    supportTree: {
      initial: [],
      traveled: []
    }
  },
  legacyChat: {},
  filters: {
    author: 'all',
    status: 'all',
    topic: 'all',
    period: 'year',
    dateFrom: moment().subtract(1, 'year').valueOf(),
    sortOrder: 'desc'
  },
  unread: {
    chatsCount: 0,
    messagesCount: 0,
    unreadByChats: {}
  }
}

export const chatsSlice = createSlice({
  name: 'chats',
  initialState: () => initialState,
  reducers: {
    openSingleChat: (state, action: PayloadAction<{orderId?: string; placeId?: number}>) => {
      state.singleChat.open = true
      state.singleChat.openParams.orderId = action?.payload.orderId
      state.singleChat.openParams.placeId = action?.payload.placeId
    },
    closeSingleChat: (state) => {
      state.singleChat.open = false
    },
    openLegacyChat: (state, action: PayloadAction<string>) => {
      state.legacyChat.openedChatId = action.payload
    },
    closeLegacyChat: (state) => {
      delete state.legacyChat.openedChatId
      delete state.legacyChat.newChatParams
    },
    setLegacyNewChatParams: (state, action: PayloadAction<ChatsSliceState['legacyChat']['newChatParams']>) => {
      state.legacyChat.newChatParams = action.payload
    },
    setAuthorFilter: (state, action: PayloadAction<ChatsFilters['author']>) => {
      state.filters.author = action.payload
    },
    setStatusFilter: (state, action: PayloadAction<ChatsFilters['status']>) => {
      state.filters.status = action.payload
    },
    setTopicFilter: (state, action: PayloadAction<ChatsFilters['topic']>) => {
      state.filters.topic = action.payload
    },
    setPeriodFilter: (state, action: PayloadAction<ChatsFilters['period']>) => {
      state.filters.period = action.payload
    },
    setDateFromFilter: (state, action: PayloadAction<Moment>) => {
      state.filters.dateFrom = action.payload.valueOf()
    },
    setDateToFilter: (state, action: PayloadAction<Moment>) => {
      state.filters.dateTo = action.payload.valueOf()
    },
    setSortOrderFilter: (state, action: PayloadAction<ChatsFilters['sortOrder']>) => {
      state.filters.sortOrder = action.payload
    },
    resetFilters: (state) => {
      state.filters = initialState.filters
    },
    closeMultiChatDrawer: (state) => {
      state.multiChat.open = false
    },
    resetMultiChatDrawerState: (state) => {
      state.multiChat.open = initialState.multiChat.open
      state.multiChat.createParams = initialState.multiChat.createParams
      state.multiChat.openParams = initialState.multiChat.openParams
      state.multiChat.supportTree = initialState.multiChat.supportTree
    },
    openMultiChatDrawerWithSupportTree: (
      state,
      action: PayloadAction<{
        path?: string[]
        orderId?: string
        placeId?: number
      }>
    ) => {
      state.multiChat.open = true
      state.multiChat.supportTree.initial = action.payload.path ?? []
      state.multiChat.createParams.orderId = action.payload.orderId
      state.multiChat.createParams.placeId = action.payload.placeId
    },
    openMultiChatDrawer: (state, action: PayloadAction<{chatId: string; ticketId?: string}>) => {
      state.multiChat.open = true
      state.multiChat.openParams.chatId = action.payload.chatId
      state.multiChat.openParams.ticketId = action.payload.ticketId
    },
    /** Нужен только в одном случае - когда мы почему то все еще не знаем chat_id и нам нужно выполнить join по ticket_id чтобы получить chat_id */
    openMultiChatDrawerWithThicketId: (state, action: PayloadAction<string>) => {
      state.multiChat.open = true
      state.multiChat.openParams.ticketId = action.payload
    },
    supportTreeForward: (state, action: PayloadAction<string>) => {
      state.multiChat.supportTree.traveled.push(action.payload)
    },
    supportTreeBack: (state) => {
      state.multiChat.supportTree.traveled.pop()
    },
    setUnreadChatsCount: (state, action: PayloadAction<number>) => {
      state.unread.chatsCount = action.payload
    },
    setUnreadMessagesCount: (state, action: PayloadAction<number>) => {
      state.unread.messagesCount = action.payload
    },
    setUnreadByChats: (state, action: PayloadAction<ChatsSliceState['unread']['unreadByChats']>) => {
      state.unread.unreadByChats = action.payload
    }
  },
  selectors: {
    selectMultiChatCreateParams: (state) => state.multiChat.createParams,
    selectMultiChatDrawerState: (state) => state.multiChat.open,
    selectSupportTreePath: (state) => state.multiChat.supportTree.initial.concat(state.multiChat.supportTree.traveled),
    selectSupportTreeCanTravelBack: (state) =>
      state.multiChat.supportTree.traveled.length > 0 &&
      !state.multiChat.openParams.chatId &&
      !state.multiChat.openParams.ticketId,
    selectMultiChatId: (state) => state.multiChat.openParams.chatId,
    selectMultiChatTicketId: (state) => state.multiChat.openParams.ticketId,
    selectSingleChatOpen: (state) => state.singleChat.open,
    selectSingleChatOpenParams: (state) => state.singleChat.openParams,
    selectLegacyOpenedChatId: (state) => state.legacyChat.openedChatId,
    selectLegacyNewChatParams: (state) => state.legacyChat.newChatParams,
    selectFilters: (state) => {
      return {
        ...state.filters,
        dateFrom: moment(state.filters.dateFrom),
        dateTo: state.filters.dateTo ? moment(state.filters.dateTo) : undefined
      }
    },
    selectFiltersDirty: (state) => {
      return (
        state.filters.author !== initialState.filters.author ||
        state.filters.status !== initialState.filters.status ||
        state.filters.topic !== initialState.filters.topic ||
        state.filters.period !== initialState.filters.period ||
        state.filters.dateFrom !== initialState.filters.dateFrom ||
        state.filters.dateTo !== initialState.filters.dateTo ||
        state.filters.sortOrder !== initialState.filters.sortOrder
      )
    },
    selectFiltersDirtyCount: (state) => {
      // не учитываем dateFrom и dateTo т.к они считаются за 1 вместе с period
      return (
        Number(state.filters.author !== initialState.filters.author) +
        Number(state.filters.status !== initialState.filters.status) +
        Number(state.filters.topic !== initialState.filters.topic) +
        Number(state.filters.period !== initialState.filters.period) +
        Number(state.filters.sortOrder !== initialState.filters.sortOrder)
      )
    },
    selectUnreadChatsCount: (state) => state.unread.chatsCount,
    selectUnreadMessagesCount: (state) => state.unread.messagesCount
  }
})

/**
 * Добавляет задержку сброса стейта при закрытии дровера мультичатов
 * чтобы избежать видимого моргания при проигрывании анимации закрытия дровера
 */
startAppListening({
  actionCreator: chatsSlice.actions.closeMultiChatDrawer,
  effect: async (_action, listenerApi) => {
    await listenerApi.delay(300)
    listenerApi.dispatch(chatsSlice.actions.resetMultiChatDrawerState())
  }
})

export type LegacyNewChatParams = {
  place_id?: number
  /** @deprecated не используйте данный параметр */
  doccenter_id?: string
  /** @deprecated не используйте данный параметр */
  path?: string
  /** @deprecated не используйте данный параметр */
  place_ids?: string
  order_id?: string
}

export type ChatsFilters = {
  author: string
  status: 'open' | 'solved' | 'pending' | 'all'
  topic: string
  period: 'week' | 'month' | 'year' | 'custom'
  dateFrom: number
  dateTo?: number
  sortOrder: 'asc' | 'desc'
}

type ChatsSliceState = {
  singleChat: {
    open: boolean
    openParams: {
      orderId?: string
      placeId?: number
    }
  }
  multiChat: {
    open: boolean
    openParams: {
      chatId?: string
      /** В большинстве случаев нужен только чтобы получить информацию о чате из ручки list, т.к эта ручка не умеет в поиск по chatId */
      ticketId?: string
    }
    createParams: {
      orderId?: string
      placeId?: number
    }
    supportTree: {
      initial: string[]
      traveled: string[]
    }
  }
  unread: {
    chatsCount: number
    messagesCount: number
    unreadByChats: Record<string, number>
  }
  legacyChat: {
    openedChatId?: string
    newChatParams?: LegacyNewChatParams
  }
  filters: ChatsFilters
}
